import React, { useState } from 'react'
import Navigation from './navigation'
import CookieConsent from './cookie-consent';
import { CookiesProvider } from 'react-cookie';
export const CookieContext = React.createContext(null);

const durationExitMain = 0
const durationMain = 1.2
const delayMain = 1.0
const delayExitMain = 0.5

const variantsMain = {
  initial: {
    opacity: 0,
    x: 0,

  },
  animate: {
    opacity: 1,
    y: 0,
    x: 0,
    transition: {
      duration: durationMain,
      delay: delayMain,
      when: "beforeChildren",
      ease: "easeInOut"
    },
  },
  exit: {
    opacity: 0,
    y: 0,
    transition: { 
      duration: durationExitMain,
      delay: delayExitMain,
      ease: "easeInOut"
    },
  },
}


const isBrowser = typeof window !== "undefined";

const Layout = ({ children }) => {
  const [allowCookies, setAllowCookies] = useState(false);

  return (
    <div className='h-full'>
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <CookieContext.Provider value={{ allowCookies: allowCookies, setAllowCookies: setAllowCookies }}>
          <Navigation />
            {children}
          <CookieConsent />
        </CookieContext.Provider>
      </CookiesProvider>
    </div>
  )
}

export default Layout