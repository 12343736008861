import React, { useContext, useEffect, useState } from "react";
import { CookieContext } from '../components/layout';
import { useCookies } from 'react-cookie';



const CookieConsent = ({}) => {
  const [ cookies, setCookie ] = useCookies(['cookie-consent']);
  const { setAllowCookies } = useContext(CookieContext);
  const [ cookiePresent, setCookiePresent ] = useState(true);
  const [ showBanner, setShowBanner ] = useState(false);

  const handleAccept = () => {
    setCookie('cookie-consent', 'allow', { maxAge: 7257600 });
    setCookiePresent(true);
    setAllowCookies(true);
  }
  const handleReject = () => {
    setCookie('cookie-consent', 'reject', { maxAge: 7257600 });
    setCookiePresent(true);
    setAllowCookies(false);
  }


  useEffect(() => {
      if (cookies["cookie-consent"]) {
        setCookiePresent(true);
        if (cookies["cookie-consent"] === 'allow') {
          setAllowCookies(true);
        }
      }
      else {
        console.log("Cookie not present");
        setShowBanner(true);
        setTimeout(() => {
          setCookiePresent(false);
        }, 1000)
        
      }
    }, []);
  

  return (
    <div className={`${showBanner ? "block" : "hidden"} ${!cookiePresent ? "opacity-95" : "opacity-0"} absolute bottom-0 w-full flex items-center justify-center bg-dark-green py-2 text-neutral-200 font-lato text-xs md:text-lg z-20 transition-opacity ease-in-out duration-1000`}>
      <div className="xl:containter w-full max-w-[1920px] mx-auto h-fit md:h-24 grid grid-cols-4 md:grid-cols-5 px-4 md:px-4 2xl:px-32">
        <div className="justify-self-start h-full text-xs md:text-2xl flex items-center col-span-2 md:col-span-3">
          <p className="">Please indicate if you are happy with cookies. <br/>Some features require cookies.</p>
        </div>
        <div className="justify-self-end col-span-2 grid grid-cols-2 gap-2 sm:gap-4 md:gap-8 items-center">
          <div className="flex items-center">
            <button className="bg-slate-800 px-2 sm:px-4 md:px-5 py-3 rounded-md" onClick={handleAccept}>
              Accept
            </button>
          </div>
          <div className="flex items-center">
            <button className="bg-red-600 px-2 sm:px-4 md:px-5 py-3 rounded-md" onClick={handleReject}>
              Reject
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CookieConsent